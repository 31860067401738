.container{
    padding: 25px;
  }

  .drawer .MuiPaper-root{
    border-top-left-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    background-color: var(--primary-color) !important;
    position: relative !important;
  }
  
  .MuiIconButton-root:hover {
    background-color: transparent !important;
  }

  .css-1w56b9-MuiButtonBase-root-MuiListItemButton-root{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  /* .css-7d66g5-MuiButtonBase-root-MuiListItemButton-root{
    padding-top: 15px !important;
    padding-bottom: 10px !important;
  } */
 
  .css-h4y409-MuiList-root{
    padding-top: 6px !important;
  }

  .MuiButtonBase-root-MuiListItemButton-root{
    padding: 23px 20px 0 !important;
  }

  .css-eeahu7{
    padding: 15px 20px 10px !important;
    margin: 10px 0 0 0 !important;
  }
  .css-1ontqvh{
    padding: 0 !important;
  }

  .MuiCollapse-vertical{
    height: 40px;
  }

  @media only screen and (max-width: 700px) {
    .container{
      padding: 10px 0 0 0;
    }
  }
  