.textField{
    width: 250px;
}

.textArea{
    width: 500px;
}

.link{
    cursor: pointer;
    padding-top: 8px;
    min-width: 120px;
    width: 120px;
    color: var(--primary-color);
    text-decoration: underline;
}

.link:hover{
    text-decoration: none;
}

.submit{
    padding: 30px 0 0 470px;
}

@media only screen and (max-width: 690px) {
    .textField{
     width: 70%;
    }
    .textArea{
        width: 95%;
    }
  }