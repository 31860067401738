.login-page {
   
    width: 50%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


.login-card {
    height: 400px;
    max-height: max-content;
    width: 50%;
    border-radius: 20px;
    box-shadow: 0px 10px 10px 5px #be9e8f;
    padding: 0 25px 10px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    background-color: white;

}

.logo{
    width: 230px;
}

@media only screen and (max-width:1440px) {
    .login-card {
        width: 70%;
        padding: 20px;
    }

} 
@media only screen and (max-width:1095px) {
    .login-card {
        width: 90%;
        padding: 20px;
    }

}
@media only screen and (max-width:767px) {
    .login-card {
        padding: 10px;
        height: unset;
        min-width: 285px;
    }

}