body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #D18D6B;
  --second-color: #1B877B;
  --button1: #CAC0B3;
  --button2: #D18D6B;
  --border: #CAC0B3;
  --table-rows: #F1E9DF;
  --bg: #C7A99B;
  --black: #000;
  --primary-font: 'Open Sans', sans-serif;
  --second-font: 'Source Sans Pro', sans-serif;
  --third-font: 'Tajawal', sans-serif;
}

.loader {
  position: fixed !important;
  top: 0;
  left: 0;
  height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  color: var(--primary-color) !important;
  z-index: 10;
}

.MuiCircularProgress-root{
  color: var(--primary-color) !important;
}

.loader>div {
  position: absolute;
  top: 50% !important;
  left: 50%;
  transform: translateX(-50%);
}

/* big container */
.big {
  background: white;
  padding: 10px 30px 30px 30px;
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  min-height: 90vh;
}

/* view pages */
.info {
  display: flex;
  flex-direction: column;
  padding: 25px 0 0 0;
}

.data {
  display: flex;
}

.split {
  height: 100%;
  width: 45%;
  min-width: 170px;
  border: 0 !important;
  top: 0;
  overflow-x: hidden;
}

/* Control the left side */
.left {
  left: 0;
  padding: 0;
  border: 0;
  margin: 0 40px 0 0;
  border-bottom: 1px solid var(--border) !important;
}
.content{
  left: 0;
  padding: 0;
  border: 0;
  margin: 20px 40px 0 0;
}

.mt-5{margin-top: 5px;}

/* Control the right side */
.right {
  right: 0;
  padding: 0;
  border: 0;
  margin: 0;
  border-bottom: 1px solid var(--border) !important;
}

.back {
  width: 80px;
  height: 34px;
  cursor: pointer;
  border-radius: 100px;
  background-color: var(--primary-color);
  border: 0;
  margin-bottom: 15px;
  right: 0;
  color: #fff;
}

/* grid tables */
.MuiDataGrid-toolbarContainer>button {
  display: none !important;
}

.buttons {
  color: var(--primary-color) !important;
}
.button.w-155,.backbtn.w-155{min-width: initial !important;width: initial !important;max-width: initial !important;padding:6px 15px}

.MuiInputLabel-root.Mui-focused {
  color: var(--border) !important;
}

.Mui-selected {
  background-color: var(--border) !important;
  color: #fff !important;
}

.MuiFormLabel-root.Mui-focused{
  color: var(--border) !important;
}

.MuiInputBase-root:after {
  border-bottom: 2px solid var(--border) !important;
}

.MuiDataGrid-root .MuiDataGrid-row{
  min-height: 50px !important;
}

.Title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--black);
  font-family: var(--second-font);
  width: 100%;
}

.Title-container>button {
  cursor: pointer;
  width: 80px;
  height: 34px;
  border-radius: 100px;
  background-color: var(--primary-color);
  border: 0;
  color: #fff;
}

.backbtn{
  cursor: pointer;
  width: 80px;
  height: 34px;
  border-radius: 100px !important;
  background-color: transparent;
  border: 1px solid var(--primary-color) !important;
  border: 0;
  color: var(--primary-color) !important;
}

.checbox-primary .MuiSvgIcon-root{
  color: var(--primary-color) !important;
}

.MuiDataGrid-toolbarContainer>button {
  display: none !important;
}

.Title-container {
  display: flex;
  color: var(--black);
  font-family: var(--second-font);
  width: 100%;
}

.actions {
  display: flex;
  padding-right: 1em !important;
}

.iconButton {
  width: 20px !important;
  height: 24px !important;
  border-radius: 10px;
  /* background-color: var(--second-color); */
  border: 0;
  color: var(--primary-color);
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--border) !important;
}

/* calendar */
.MuiPickersPopper-root .Mui-selected {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.MuiPickersPopper-root .MuiPickersDay-root.Mui-selected{
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.MuiPickersPopper-root .PrivatePickersMonth-root.Mui-selected{
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.MuiPickersPopper-root .MuiPickersDay-root.Mui-selected{
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

/* select users button */
.MuiTouchRipple-root{
  display: none !important;
  /* background-color: transparent !important; */
}

.MuiButtonBase-root-MuiButton-root:hover{
  background-color: transparent !important;
}

/* radio button */
.css-1a5icme.Mui-checked{
  color: var(--primary-color) !important;
}
.PrivateSwitchBase-input{
  color: var(--primary-color) !important;
}

.MuiRadio-root.Mui-checked{
  color: var(--primary-color) !important;
}
/* gridbar container */
.MuiDataGrid-toolbarContainer {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.MuiBox-root{
  background: white;
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}

.button {
  cursor: pointer;
  background-color: var(--primary-color) !important;
  color: #fff !important;
  border-radius: 40px !important;
  border: 0;
  max-width: 100px;
  max-height: 33px;
  width: 90px;
}

.stack{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.css-12tykpa-MuiFormControl-root-MuiTextField-root{
  margin: 8px !important;
}

.css-17u0ckk{
  margin: 8px !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input{
  padding: 0;
}

/* table res */
.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft div{
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

@media only screen and (max-width: 1716px) {
  .actions{
   flex-direction: column;
  }
}
@media only screen and (max-width: 696px) {
.button.w-155,.backbtn.w-155{margin-top: 8px;line-height: 17px;}
}
@media only screen and (max-width: 625px) {
  .data{
   flex-wrap: wrap;
  }
}